<template>
  <div
    v-if="pagination.total"
    :pdt="resolution.width > resolution.mobile ? `4xl` : `xl`"
    :pdb="resolution.width > resolution.mobile ? `5xl` : `2xl`"
  >
    <client-only>
      <v-pagination
        v-model="pagination.page"
        :size="size"
        active-color="secondary"
        variant="flat"
        :length="pagination.count"
        :total-visible="totalVisible"
        :key="pagination.page"
        @click="setTimestamp"
      />
    </client-only>
  </div>
</template>

<script lang="ts">
import { useGlobalStore } from '~/store/global'

export default {
  props: {
    pagination: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    },
    resolution: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    }
  },
  setup (props) {

    // DOM 처리
    function optimizerDom () {
      // 버튼 업데이트
      const pageBtns = document.querySelectorAll('[arialabel]')
      pageBtns.forEach((btn: any) => {
        btn.setAttribute('aria-label', btn.getAttribute('arialabel'))
        btn.setAttribute('title', btn.getAttribute('arialabel'))
      })
    }

    const totalVisible: any = ref(10)
    const size: any = ref('default')

    function updateUi () {
      if (props.resolution.width < props.resolution.min) {
        totalVisible.value = 4
        size.value = 'small'
      } else if (props.resolution.width < props.resolution.mobile) {
        totalVisible.value = 4
        size.value = 'small'
      } else if (props.resolution.width < props.resolution.tablet) {
        totalVisible.value = 8
        size.value = 'default'
      } else if (props.resolution.width < props.resolution.notebook) {
        totalVisible.value = 10
        size.value = 'default'
      } else {
        totalVisible.value = 10
        size.value = 'default'
      }
    }

    // 메모리 비우기
    function restore () {
      totalVisible.value = null
      size.value = null
    }

    // 
    onBeforeUnmount (() => {
      restore()
    })

    // 서버, 클라이언트 체크
    onMounted ( async () => {
      optimizerDom()
      updateUi()
    })

    return {
      totalVisible,
      size,
      updateUi
    }
  },
  watch: {
    'resolution.width' (to) {
      this.updateUi()
    }
  },
  methods: {
    setTimestamp () {
      this.pagination.pagestamp = new Date().getTime()
      const globalStore = useGlobalStore()
      globalStore.setPopstateUnavailable()
    }
  }
}
</script>